import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/state';
import {
  setLeague,
  setLeagueAdministrator,
  setLeaguePlayerId,
  setLeagues,
  setPlayer,
  setUserId,
} from 'src/app/store/user/user.actions';
import { getLeague, getLeagues, getUserId } from 'src/app/store/user/user.selector';
import { LeagueSummary } from 'src/model/league';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css'],
})
export class HomeContentComponent implements OnInit {
  userId$: Observable<number | undefined>;
  leagues$: Observable<LeagueSummary[] | undefined>;
  league$: Observable<LeagueSummary | undefined>;

  selectedLeague: LeagueSummary;

  constructor(public auth: AuthService, private store: Store<State>, private api: ApiService) {
    // once authenticated, get the user id and leagues, auto-selecting a league if possible
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        // get the access token
        this.auth.getAccessTokenSilently().subscribe((token) => {
          // decode the access token
          const claims = jwtDecode<{ sub_id: number }>(token);

          this.userId$.subscribe((userId) => {
            if (!userId) {
              // dispatch the user id to the store
              this.store.dispatch(setUserId(claims.sub_id));
            }

            // get the player
            this.api.findPlayerById$(claims.sub_id).subscribe({
              next: (res) => {
                // dispatch the player to the store
                this.store.dispatch(setPlayer(res));
              },
              error: (e) => {
                console.log(e);
              },
            });
          });

          this.league$.subscribe((league) => {
            if (!league) {
              // check how many leagues the user is in
              this.api.findLeaguesByPlayerId$(claims.sub_id).subscribe({
                next: (res) => {
                  // set the leagues in the store
                  this.store.dispatch(setLeagues(res.leagues));

                  // if there is only one, dispatch the league id to the store
                  if (res.returnedRecords === 1) {
                    this.store.dispatch(setLeague(res.leagues[0]));
                  }
                },
                error: (e) => {
                  console.log(e);
                },
              });
            } else {
              // get the league player
              this.api.findLeaguePlayer$(league.id, claims.sub_id).subscribe({
                next: (res) => {
                  // dispatch the league player id to the store
                  this.store.dispatch(setLeaguePlayerId(res.id));

                  // if the player is an administrator, dispatch that to the store
                  this.store.dispatch(setLeagueAdministrator(res.administrator));
                },
                error: (e) => {
                  console.log(e);
                },
              });
            }
          });
        });
      }
    });
  }

  ngOnInit() {
    this.userId$ = this.store.select(getUserId);
    this.leagues$ = this.store.select(getLeagues);
    this.league$ = this.store.select(getLeague);
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  onLeagueSelected() {
    this.store.dispatch(setLeague(this.selectedLeague));
  }
}
