import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Chat, PagedChat } from 'src/model/chat';
import { GameSummary } from 'src/model/game';
import { PagedLeague } from 'src/model/league';
import { LeagueGame, PagedLeagueGame } from 'src/model/league-game';
import { LeaguePlayer, LeaguePlayerSummary } from 'src/model/league-player';
import { LeagueSeasonPlayerSummary } from 'src/model/league-season-player';
import { Pick } from 'src/model/pick';
import { Player } from 'src/model/player';
import { PagedRanking } from 'src/model/ranking';
import { Season } from 'src/model/season';
import { WeekSummary } from 'src/model/week';
import config from '../../auth_config.json';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  ping$() {
    return this.http.get(`${config.apiUri}/leagues/1/chats?pageSize=10`);
  }

  /** Players */

  /**
   * Find a player using the player's unique identifier.
   *
   * @param playerId The player unique identifier
   * @returns The player
   */
  findPlayerById$(playerId: number): Observable<Player> {
    return this.http.get(`${config.apiUri}/players/${playerId}`) as Observable<Player>;
  }

  /**
   * Find a league player using the league and player unique identifiers.
   *
   * @param leagueId The league unique identifier
   * @param playerId The player unique identifier
   * @returns The league player
   */
  findLeaguePlayer$(leagueId: number, playerId: number): Observable<LeaguePlayer> {
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/players/${playerId}`) as Observable<LeaguePlayer>;
  }

  /**
   * Find all players in a league using the league unique identifier.
   *
   * @param leagueId The league unique identifier
   * @returns An array of league players
   */
  findLeaguePlayersByLeagueId$(leagueId: number, seasonId?: number): Observable<LeaguePlayerSummary[]> {
    const pathParams = seasonId ? `?${this.buildPathParams({ seasonId })}` : '';
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/players${pathParams}`) as Observable<
      LeaguePlayerSummary[]
    >;
  }

  /**
   * Find all players in a league season using the league and season unique identifiers.
   *
   * @param leagueId The league unique identifier
   * @param seasonId The season unique identifier
   * @returns An array of league season players
   */
  findLeagueSeasonPlayers$(leagueId: number, seasonId: number): Observable<LeagueSeasonPlayerSummary[]> {
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/seasons/${seasonId}/regular/players`) as Observable<
      LeagueSeasonPlayerSummary[]
    >;
  }

  /**
   * Find all leagues in which the player is a member using the player's unique identifier.
   *
   * @param playerId The player unique identifier
   * @returns A single page of leagues in which the player is a member
   */
  findLeaguesByPlayerId$(playerId: number): Observable<PagedLeague> {
    return this.http.get(`${config.apiUri}/players/${playerId}/leagues`) as Observable<PagedLeague>;
  }

  /**
   * Update a player's profile information.
   *
   * @param player The new player profile information
   * @returns The updated player
   */
  updatePlayer$(player: Player): Observable<Player> {
    return this.http.put(`${config.apiUri}/players/${player.id}`, player) as Observable<Player>;
  }

  /** Seasons */

  /**
   * Get all seasons for a specific league.
   *
   * @param leagueId The league unique identifier
   * @returns All seasons for the league
   */
  getSeasonsByLeagueId$(leagueId: number): Observable<Season[]> {
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/seasons`) as Observable<Season[]>;
  }

  /**
   * Find the most recent season in which a player participated as a member of a league.
   * @param leagueId The league unique identifier
   * @param playerId The player unique identifier
   * @returns The most recent season in which the player participated
   */
  findCurrentSeasonByLeagueIdAndPlayerId$(leagueId: number, playerId: number): Observable<Season> {
    return this.http.get(
      `${config.apiUri}/leagues/${leagueId}/players/${playerId}/seasons/current`
    ) as Observable<Season>;
  }

  /** Weeks */

  /**
   * Find all weeks which are part of a specific season.
   *
   * @param seasonId The season unique identifier
   * @returns All weeks which are part of the season
   */
  findWeeksBySeasonId$(seasonId: number): Observable<WeekSummary[]> {
    return this.http.get(`${config.apiUri}/seasons/${seasonId}/weeks`) as Observable<WeekSummary[]>;
  }

  /**
   * Find the current week in a specific season. The current week is the earliest week with unplayed games.
   *
   * @param seasonId The season unique identifier
   * @returns The current week in the season
   */
  findCurrentWeekBySeasonId$(seasonId: number): Observable<WeekSummary> {
    return this.http.get(`${config.apiUri}/seasons/${seasonId}/weeks/current`) as Observable<WeekSummary>;
  }

  /** Games */

  /**
   * Find all games which happen during a specific week.
   *
   * @param seasonId The season unique identifier
   * @param weekId The week unique identifier
   * @returns All games which happen during the week
   */
  findGamesByWeekId$(seasonId: number, weekId: number): Observable<GameSummary[]> {
    return this.http.get(`${config.apiUri}/seasons/${seasonId}/weeks/${weekId}/games`) as Observable<GameSummary[]>;
  }

  /**
   * Find all games which are pickable for a specific league.
   *
   * @param leagueId The league unique identifier
   * @param options Options to filter the games
   * @param options.seasonId The season unique identifier
   * @param options.weekId The week unique identifier
   * @returns A single page of pickable league games
   */
  findLeagueGames$(
    leagueId: number,
    options: {
      seasonId?: number;
      weekId?: number;
    }
  ): Observable<PagedLeagueGame> {
    const pathParams = options ? `?${this.buildPathParams(options)}` : '';
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/games${pathParams}`) as Observable<PagedLeagueGame>;
  }

  /**
   * Add a pickable game to a league.
   *
   * @param leagueId The league unique identifier
   * @param gameId The game unique identifier
   * @returns The newly created league game
   */
  addLeagueGame$(leagueId: number, gameId: number): Observable<LeagueGame> {
    return this.http.post(`${config.apiUri}/leagues/${leagueId}/games`, { gameId }) as Observable<LeagueGame>;
  }

  /**
   * Remove a pickable game from a league.
   *
   * @param leagueId The league unique identifier
   * @param gameId The game unique identifier
   */
  deleteLeagueGame$(leagueId: number, gameId: number): Observable<object> {
    return this.http.delete(`${config.apiUri}/leagues/${leagueId}/games/${gameId}`);
  }

  /** Picks */

  /**
   * Find a league player's pick for a specific league game.
   *
   * @param leagueGameId The league game unique identifier
   * @param leaguePlayerId The league player unique identifier
   * @returns The league player's pick; empty if the player has not made a pick
   */
  findPickByLeagueGameIdAndLeaguePlayerId$(leagueGameId: number, leaguePlayerId: number): Observable<Pick> {
    const pathParams = `?${this.buildPathParams({ leagueGameId, leaguePlayerId })}`;
    return this.http.get(`${config.apiUri}/picks${pathParams}`) as Observable<Pick>;
  }

  /**
   * Add a league player's pick for a specific league game.
   *
   * @param pick The league player's pick
   * @returns The newly created pick
   */
  addPick$(pick: Pick): Observable<Pick> {
    return this.http.put(`${config.apiUri}/picks`, pick) as Observable<Pick>;
  }

  /** Rankings */

  /**
   * Get league rankings for a specific season. Rankings may be regular season or bowl season.
   *
   * @param leagueId The league unique identifier
   * @param seasonId The season unique identifier
   * @param seasonType The season type (regular|bowl)
   * @returns A single page of season rankings
   */
  getRankingsByLeagueIdSeasonIdAndSeasonType$(
    leagueId: number,
    seasonId: number,
    seasonType: string
  ): Observable<PagedRanking> {
    return this.http.get(
      `${config.apiUri}/leagues/${leagueId}/seasons/${seasonId}/${seasonType}/rankings`
    ) as Observable<PagedRanking>;
  }

  /** Chats */

  /**
   * Find all chats for a specific league.
   *
   * @param leagueId The league unique identifier
   * @param options Options to filter the games
   * @param options.pageSize The number of records to return per page
   * @returns A single page of chats
   */
  findChatsByLeagueId$(
    leagueId: number,
    options?: { sort?: string; firstRecord?: number; pageSize?: number }
  ): Observable<PagedChat> {
    const pathParams = options ? `?${this.buildPathParams(options)}` : '';
    return this.http.get(`${config.apiUri}/leagues/${leagueId}/chats${pathParams}`) as Observable<PagedChat>;
  }

  private buildPathParams(params: { [key: string]: number | string }) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }

  /**
   * Add a chat to a league.
   *
   * @param chat The chat to add
   * @returns The newly created chat
   */
  addChat$(chat: Chat): Observable<Chat> {
    return this.http.post(`${config.apiUri}/chats`, chat) as Observable<Chat>;
  }
}
