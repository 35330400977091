import { Component, Input, OnInit } from '@angular/core';
import { ChatSummary } from 'src/model/chat';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  // inputs
  @Input() playerId: number;
  @Input() chat: ChatSummary;

  // variables
  chatTime: string;
  defaultImgSrc = '../../../assets/images/profile_picture.png';

  ngOnInit() {
    // transform the lastUpdated timestamp into a readable date
    this.chatTime = new Date(this.chat.lastUpdated * 1000).toLocaleString('en-US');
  }
}
