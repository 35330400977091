import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/state';
import { getUserId } from 'src/app/store/user/user.selector';
import { Player } from 'src/model/player';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  // player id
  playerId: number | undefined;

  // player
  player: Player | undefined;

  // variables
  defaultImgSrc = '../../../assets/images/profile_picture.png';

  constructor(private store: Store<State>, private api: ApiService) {}

  ngOnInit() {
    // get the player
    this.store.select(getUserId).subscribe((playerId) => {
      this.playerId = playerId;
    });
    this.api.findPlayerById$(this.playerId).subscribe({
      next: (res) => {
        this.player = res;
      },
    });
  }

  onFileUpload(event) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      // get the file
      const file = files[0];

      // base64 encode the file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;

        // update the player
        this.player.profilePicture = base64;

        this.api.updatePlayer$(this.player).subscribe({
          next: (res) => {
            this.player = res;
          },
        });
      };
    }
  }
}
