<div class="game d-flex justify-content-center flex-column rounded m-2">
  <div class="d-flex flex-row p-2">
    <div class="d-flex flex-fill justify-content-center">
      <span>{{ leagueGame.game.venue.name }}</span>
    </div>
  </div>
  <div class="d-flex flex-row flex-fill justify-content-center">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-center font-weight-bold border border-dark">
        <span>{{ leagueGame.game.awayTeam.school }}</span>
      </div>
      <div class="d-flex flex-row">
        <img class="border border-dark p-2" [src]="awayHelmetPath" alt="{{ leagueGame.game.awayTeam.school }}" />
      </div>
      <div class="d-flex flex-row">
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.awayTeam.id, 1)"
          (click)="makePick(leagueGame.game.awayTeam.id, 1)"
        >
          1
        </div>
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.awayTeam.id, 2)"
          (click)="makePick(leagueGame.game.awayTeam.id, 2)"
        >
          2
        </div>
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.awayTeam.id, 3)"
          (click)="makePick(leagueGame.game.awayTeam.id, 3)"
        >
          3
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-center font-weight-bold border border-dark">
        <span>{{ leagueGame.game.homeTeam.school }}</span>
      </div>
      <div class="d-flex flex-row">
        <img class="border border-dark p-2" [src]="homeHelmetPath" alt="{{ leagueGame.game.homeTeam.school }}" />
      </div>
      <div class="d-flex flex-row">
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.homeTeam.id, 1)"
          (click)="makePick(leagueGame.game.homeTeam.id, 1)"
        >
          1
        </div>
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.homeTeam.id, 2)"
          (click)="makePick(leagueGame.game.homeTeam.id, 2)"
        >
          2
        </div>
        <div
          class="flex-fill border border-dark text-center font-weight-bold p-2"
          [ngClass]="confidenceClass(leagueGame.game.homeTeam.id, 3)"
          (click)="makePick(leagueGame.game.homeTeam.id, 3)"
        >
          3
        </div>
      </div>
    </div>
  </div>
</div>
