<div class="nav-container">
  <nav class="navbar navbar-expand-md navbar-dark" style="background-color: black">
    <div class="container m-0">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        [attr.aria-expanded]="!isCollapsed"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto d-flex flex-fill justify-content-around">
          <li class="nav-item">
            <a routerLink="/" class="pickem-nav-link">Home</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <a routerLink="picks" class="pickem-nav-link">Picks</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <a routerLink="profile" class="pickem-nav-link">Profile</a>
          </li>
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && (isLeagueAdministrator$ | async)">
            <a routerLink="administration" class="pickem-nav-link">Administration</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <span class="pickem-nav-link" (click)="logout()">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
