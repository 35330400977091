<div *ngIf="player$ | async as player" class="d-flex flex-row rounded flex-fill p-2 bg-light">
  <img class="profile-picture p-2" [src]="player.profilePicture ?? defaultImgSrc" />
  <input
    class="form-control form-control-lg ml-2 p-2 align-self-center"
    type="text"
    placeholder="Type a message..."
    (keyup.enter)="onChatEntered(chat.chat)"
    [(ngModel)]="chat.chat"
  />
</div>
