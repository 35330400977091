import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/user/user.reducer';
import { getLeague, getLeaguePlayerId, getUserId } from 'src/app/store/user/user.selector';
import { GameSummary } from 'src/model/game';
import { LeagueGameSummary } from 'src/model/league-game';
import { Season } from 'src/model/season';
import { WeekSummary } from 'src/model/week';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
})
export class AdministrationComponent implements OnInit {
  // observables
  leaguePlayerId$: Observable<number | undefined>;

  // league id
  leagueId: number | undefined;

  // selected season
  selectedSeason: Season | undefined;

  // season weeks
  weeks: WeekSummary[] = [];

  // selected week
  selectedWeek: number | undefined;

  // games
  games: { isSelected: boolean; game: GameSummary }[] = [];

  constructor(private api: ApiService, private store: Store<State>) {
    combineLatest([this.store.select(getUserId), this.store.select(getLeague)]).subscribe(([playerId, league]) => {
      // set the league id
      this.leagueId = league.id;

      // get the current season
      this.api.findCurrentSeasonByLeagueIdAndPlayerId$(1, playerId).subscribe({
        next: (res) => {
          // select the current season
          this.selectedSeason = res;

          // get all weeks
          this.api.findWeeksBySeasonId$(this.selectedSeason.id).subscribe({
            next: (res) => {
              this.weeks = res;

              // find the current week
              this.api.findCurrentWeekBySeasonId$(this.selectedSeason.id).subscribe({
                next: (res) => {
                  this.selectWeek(res);
                },
              });
            },
          });
        },
      });
    });
  }

  selectWeek(week: WeekSummary) {
    this.selectedWeek = week.id;

    // get currently selected games for the week
    let selectedGames: LeagueGameSummary[] = [];
    this.api.findLeagueGames$(this.leagueId, { seasonId: this.selectedSeason.id, weekId: week.id }).subscribe({
      next: (res) => {
        selectedGames = res.leagueGames;
      },
      complete: () => {
        // get all games for the week
        this.api.findGamesByWeekId$(this.selectedSeason.id, week.id).subscribe({
          next: (res) => {
            // map the games to include whether they are selected
            this.games = res.map((game) => ({
              game,
              isSelected: selectedGames.some((selectedGame) => selectedGame.game.id === game.id),
            }));
          },
        });
      },
    });
  }

  ngOnInit() {
    this.leaguePlayerId$ = this.store.select(getLeaguePlayerId);
  }
}
