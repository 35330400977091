<div class="d-flex flex-row rounded flex-fill bg-light">
  <div class="d-flex flex-column p-2">
    <img class="profile-picture" [src]="chat.player.profilePicture ?? defaultImgSrc" />
    <div class="text-center small font-weight-light">{{ chat.player.firstName }} {{ chat.player.lastName }}</div>
  </div>
  <div class="d-flex flex-column p-2 flex-fill">
    <div class="text-center small font-weight-light">{{ chatTime }}</div>
    <div class="p-2">{{ chat.chat }}</div>
  </div>
</div>
