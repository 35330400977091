import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { LeagueGameSummary } from 'src/model/league-game';
import { Pick } from 'src/model/pick';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css'],
})
export class GameComponent implements OnInit, OnChanges {
  // inputs
  @Input() leaguePlayerId: number;
  @Input() leagueGame: LeagueGameSummary;

  // loading?
  loading = true;

  // pick
  pick: Pick;

  // helmet image paths
  homeHelmetPath;
  awayHelmetPath;

  constructor(private api: ApiService) {}

  ngOnInit() {
    // fetch the pick
    this.api.findPickByLeagueGameIdAndLeaguePlayerId$(this.leagueGame.id, this.leaguePlayerId).subscribe({
      next: (pick) => (this.pick = pick),
    });

    // set up helmet image paths
    this.homeHelmetPath = `../../../assets/images/teams/helmets/${this.sanitizeTeamName(
      this.leagueGame.game.homeTeam.school
    )}.gif`;
    this.awayHelmetPath = `../../../assets/images/teams/helmets/${this.sanitizeTeamName(
      this.leagueGame.game.awayTeam.school
    )}.gif`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.leaguePlayerId) {
      // fetch the pick
      this.api.findPickByLeagueGameIdAndLeaguePlayerId$(this.leagueGame.id, this.leaguePlayerId).subscribe({
        next: (pick) => (this.pick = pick),
      });
    }
  }

  makePick(winningTeamId: number, confidence: number) {
    this.api
      .addPick$({
        leaguePlayerId: this.leaguePlayerId,
        leagueGameId: this.leagueGame.id,
        winningTeamId,
        confidence,
      })
      .subscribe({
        next: () => {
          // refresh the pick
          this.api.findPickByLeagueGameIdAndLeaguePlayerId$(this.leagueGame.id, this.leaguePlayerId).subscribe({
            next: (pick) => (this.pick = pick),
          });
        },
      });
  }

  confidenceClass(teamId: number, confidence: number): string {
    // get the pick
    if (this.pick?.winningTeamId === teamId && this.pick?.confidence === confidence) {
      // this is the pick
      if (!this.leagueGame.game.winningTeamId) {
        // game has not been played
        return 'text-white bg-dark';
      } else if (this.leagueGame.game.winningTeamId === teamId) {
        // correct pick
        return 'text-white bg-success';
      } else {
        // incorrect pick
        return 'text-white bg-danger';
      }
    } else {
      // no pick
      return 'bg-white';
    }
  }

  private sanitizeTeamName(teamName: string) {
    return teamName
      .replaceAll(' ', '_')
      .replaceAll(/'/g, '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(/\./g, '')
      .replaceAll('é', 'e')
      .replaceAll('&', '');
  }
}
