import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, firstValueFrom, from, lastValueFrom } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addHeader(request, next));
  }

  async addHeader(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await firstValueFrom(this.auth.getAccessTokenSilently());

    const modifiedRequest = request.clone({
      setHeaders: {
        'X-Session-Token': `${token}`,
      },
    });

    return lastValueFrom(next.handle(modifiedRequest));
  }
}
