import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/state';
import { getLeague, getUserId } from 'src/app/store/user/user.selector';
import { Chat } from 'src/model/chat';
import { Player } from 'src/model/player';

@Component({
  selector: 'app-chat-entry',
  templateUrl: './chat-entry.component.html',
  styleUrls: ['./chat-entry.component.css'],
})
export class ChatEntryComponent implements OnInit {
  @Output() newChat = new EventEmitter<Chat>();

  // player id
  playerId: number | undefined;

  // league id
  leagueId: number | undefined;

  // chat
  chat: Chat;

  // observables
  player$: Observable<Player | undefined>;

  // variables
  defaultImgSrc = '../../../assets/images/profile_picture.png';

  constructor(private store: Store<State>, private api: ApiService) {}

  ngOnInit() {
    // get the player
    this.store.select(getUserId).subscribe((playerId) => {
      this.playerId = playerId;
    });
    this.store.select(getLeague).subscribe((league) => {
      this.leagueId = league.id;
    });
    this.player$ = this.api.findPlayerById$(this.playerId);

    // initialize the chat
    this.chat = {
      leagueId: this.leagueId,
      playerId: this.playerId,
      chat: '',
    };
  }

  onChatEntered(chat: string) {
    // update the chat
    this.chat.chat = chat;

    // save the chat
    this.api.addChat$(this.chat).subscribe({
      next: () => {
        // emit the new chat
        this.newChat.emit(this.chat);

        // reset the chat
        this.chat = {
          leagueId: this.leagueId,
          playerId: this.playerId,
          chat: '',
        };
      },
      error: (e) => {
        console.log(e);
      },
    });
  }
}
