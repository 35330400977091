<div class="h-100">
  <div
    #chatContainer
    *ngIf="chats.length"
    class="justify-content-center p-2 h-100 overflow-auto"
    style="height: xyz"
    [scrollTop]="chatContainer.scrollHeight"
  >
    <app-chat class="bg-light m-1" *ngFor="let chat of chats" [playerId]="playerId" [chat]="chat"></app-chat>
    <app-chat-entry class="bg-light m-1" (newChat)="addChat($event)"></app-chat-entry>
  </div>
</div>
