<div class="d-flex column h-100 justify-content-center" *ngIf="player">
  <div class="align-items-center profile-header text-center text-md-left">
    <div class="row">
      <div class="col p-2">
        <div
          class="image-container p-2"
          [ngStyle]="{ backgroundImage: 'url(' + player.profilePicture ?? defaultImgSrc + ')' }"
        ></div>
        <label for="avatar-input-file">
          <div class="icon-wrapper">
            <input type="file" id="avatar-input-file" class="p-2" accept="image/*" (change)="onFileUpload($event)" />
            <mat-icon class="icon" fontIcon="edit"></mat-icon>
          </div>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h2>{{ player.firstName }} {{ player.lastName }}</h2>
        <p class="lead text-muted">{{ player.email }}</p>
      </div>
    </div>
  </div>
</div>
