import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'src/app/store/state';
import { clearUserId } from 'src/app/store/user/user.actions';
import { getIsLeagueAdministrator } from 'src/app/store/user/user.selector';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {
  isCollapsed = true;
  faUser = faUser;
  faRightToBracket = faRightToBracket;

  isLeagueAdministrator$: Observable<boolean>;

  constructor(public auth: AuthService, private store: Store<State>, @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit() {
    this.isLeagueAdministrator$ = this.store.select(getIsLeagueAdministrator);
  }

  logout() {
    // clear the store
    this.store.dispatch(clearUserId());

    // logout
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}
