import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './user.reducer';

export const getUserId = createSelector(createFeatureSelector<State>('user'), (state) => state.userId);
export const getPlayer = createSelector(createFeatureSelector<State>('user'), (state) => state.player);
export const getLeagues = createSelector(createFeatureSelector<State>('user'), (state) => state.leagues);
export const getLeague = createSelector(createFeatureSelector<State>('user'), (state) => state.league);
export const getLeaguePlayerId = createSelector(createFeatureSelector<State>('user'), (state) => state.leaguePlayerId);
export const getIsLeagueAdministrator = createSelector(
  createFeatureSelector<State>('user'),
  (state) => state.isLeagueAdministrator
);
