<div class="d-flex flex-column h-100">
  <!-- Season selector: allow the user to select a different season -->
  <div *ngIf="seasons && selectedSeason" class="d-flex justify-content-center m-2 p-2 bg-light bg-opacity-10 rounded">
    <select [(ngModel)]="selectedSeason" (change)="onSeasonSelected()">
      <option *ngFor="let season of seasons" [ngValue]="season">{{ season.year }}</option>
    </select>
  </div>
  <!-- /Season selector -->

  <div class="d-flex justify-content-center flex-grow-1 p-2 m-2 bg-light bg-opacity-10 rounded overflow-auto">
    <div *ngIf="pagedRankings$ | async as pagedRankings" class="d-flex flex-fill flex-wrap justify-content-center p-2">
      <table class="table table-striped">
        <thead>
          <tr class="d-none d-sm-table-row">
            <th colspan="8" class="text-center">Current</th>
            <th colspan="3" class="text-center">Last Week</th>
          </tr>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Score</th>
            <th>Points Back</th>
            <th>W</th>
            <th>L</th>
            <th class="d-none d-sm-table-cell">No Pick</th>
            <th class="d-none d-sm-table-cell">%</th>
            <th class="d-none d-sm-table-cell">Score</th>
            <th class="d-none d-sm-table-cell">W</th>
            <th class="d-none d-sm-table-cell">L</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let ranking of pagedRankings.rankings; let i = index"
            [ngClass]="{ 'font-weight-bold': ranking.player.id === playerId }"
          >
            <td>{{ i + 1 }}</td>
            <td>{{ ranking.player.firstName }} {{ ranking.player.lastName }}</td>
            <td>{{ ranking.score }}</td>
            <td>
              {{ pagedRankings.rankings[0].score - pagedRankings.rankings[i].score }}
            </td>
            <td>{{ ranking.win }}</td>
            <td>{{ ranking.loss }}</td>
            <td class="d-none d-sm-table-cell">{{ ranking.noPick }}</td>
            <td class="d-none d-sm-table-cell">
              {{ ((ranking.win / (ranking.win + ranking.loss)) * 100).toFixed(2) }}
            </td>
            <td class="d-none d-sm-table-cell">{{ ranking.lastWeekScore }}</td>
            <td class="d-none d-sm-table-cell">{{ ranking.lastWeekWin }}</td>
            <td class="d-none d-sm-table-cell">{{ ranking.lastWeekLoss }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
