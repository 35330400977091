import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/state';
import { getLeague, getUserId } from 'src/app/store/user/user.selector';
import { PagedRanking } from 'src/model/ranking';
import { Season } from 'src/model/season';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.css'],
})
export class StandingsComponent implements OnInit {
  // observables
  pagedRankings$: Observable<PagedRanking>;

  // player id
  playerId: number | undefined;

  // league id
  leagueId: number | undefined;

  // all seasons
  seasons: Season[] | undefined;

  // selected season
  selectedSeason: Season | undefined;

  constructor(private store: Store<State>, private api: ApiService) {}

  ngOnInit() {
    // get the player
    this.store.select(getUserId).subscribe((playerId) => {
      this.playerId = playerId;
    });

    // get the league
    this.store.select(getLeague).subscribe((league) => {
      this.leagueId = league.id;

      // get all seasons
      this.api.getSeasonsByLeagueId$(this.leagueId).subscribe({
        next: (res) => {
          this.seasons = res;

          // get the current season
          this.api.findCurrentSeasonByLeagueIdAndPlayerId$(1, this.playerId).subscribe({
            next: (res) => {
              // select the current season
              this.selectedSeason = this.seasons.find((s) => s.id === res.id);

              // get the rankings
              this.pagedRankings$ = this.api.getRankingsByLeagueIdSeasonIdAndSeasonType$(
                this.leagueId,
                this.selectedSeason.id,
                'regular'
              );
            },
          });
        },
      });
    });
  }

  onSeasonSelected() {
    // refresh the rankings
    this.pagedRankings$ = this.api.getRankingsByLeagueIdSeasonIdAndSeasonType$(
      this.leagueId,
      this.selectedSeason.id,
      'regular'
    );
  }
}
