<div class="d-flex justify-content-center h-100" style="background-color: black">
  <!-- Responsive login button: show if NOT authenticated -->
  <button
    *ngIf="(auth.isAuthenticated$ | async) === false"
    class="btn btn-primary btn-block w-25"
    id="qsLoginBtn"
    (click)="loginWithRedirect()"
  >
    Log in
  </button>
  <!-- /Responsive login button -->

  <!-- League selector: show if user participates in multiple leagues and has not yet selected one -->
  <div *ngIf="(auth.isAuthenticated$ | async) && (leagues$ | async) && (league$ | async) === undefined">
    <select [(ngModel)]="selectedLeague" (ngModelChange)="onLeagueSelected()" class="form-control w-100">
      <option *ngFor="let league of leagues$ | async" [ngValue]="league">{{ league.name }}</option>
    </select>
  </div>
  <!-- /League selector -->

  <!-- Standings, chat, and chat entry: show if a league has been selected -->
  <div *ngIf="league$ | async as league" class="d-flex">
    <app-chat-container class="chat-container d-none d-lg-block" style="background-color: black"></app-chat-container>
    <app-standings class="flex-fill" style="background-color: black"></app-standings>
  </div>
</div>
