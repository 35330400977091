import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/state';
import { getLeague, getPlayer } from 'src/app/store/user/user.selector';
import { Chat, ChatSummary } from 'src/model/chat';
import { LeagueSummary } from 'src/model/league';
import { Player } from 'src/model/player';

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.css'],
})
export class ChatContainerComponent implements OnInit {
  // player id
  playerId: number | undefined;

  // player
  player: Player | undefined;

  // league id
  leagueId: number | undefined;

  // league
  league: LeagueSummary | undefined;

  // chats to display
  chats: ChatSummary[] = [];

  constructor(private store: Store<State>, private api: ApiService) {}

  ngOnInit() {
    // get the player
    this.store.select(getPlayer).subscribe((player) => {
      this.playerId = player.id;
      this.player = player;
    });

    // get the league
    this.store.select(getLeague).subscribe((league) => {
      this.leagueId = league.id;
      this.league = league;

      // get chats
      this.api
        .findChatsByLeagueId$(this.leagueId, { sort: 'lastUpdated:DESC', firstRecord: 0, pageSize: 25 })
        .subscribe({
          next: (res) => {
            this.chats = res.chats.reverse();
          },
        });
    });
  }

  addChat(chat: Chat) {
    console.log('chat', chat);
    this.chats.push({
      id: chat.id,
      league: { id: this.leagueId, name: this.league.name },
      player: {
        id: this.player.id,
        firstName: this.player.firstName,
        lastName: this.player.lastName,
        profilePicture: this.player.profilePicture,
        email: this.player.email,
      },
      chat: chat.chat,
      lastUpdated: Date.now() / 1000,
    });
  }
}
