import { createAction } from '@ngrx/store';
import { LeagueSummary } from 'src/model/league';
import { Player } from 'src/model/player';

export const setUserId = createAction('[User ID] Set User ID', (userId: number) => ({ userId }));
export const clearUserId = createAction('[User ID] Clear User ID');
export const setPlayer = createAction('[Player] Set Player', (player: Player) => ({ player }));

export const setLeagues = createAction('[League] Set Leagues', (leagues: LeagueSummary[]) => ({ leagues }));
export const setLeague = createAction('[League] Set League ID', (league: LeagueSummary) => ({ league }));
export const setLeaguePlayerId = createAction('[League] Set League Player ID', (leaguePlayerId: number) => ({
  leaguePlayerId,
}));
export const setLeagueAdministrator = createAction(
  '[League] Set League Administrator',
  (isLeagueAdministrator: boolean) => ({ isLeagueAdministrator })
);
