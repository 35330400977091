<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading" class="h-100 overflow-auto">
  <div class="d-flex justify-content-center p-2">
    <div *ngIf="seasons && selectedSeason">
      <select [(ngModel)]="selectedSeason" (change)="onSeasonSelected()">
        <option *ngFor="let season of seasons" [ngValue]="season">{{ season.year }}</option>
      </select>
    </div>
    <div *ngIf="leaguePlayers && selectedLeaguePlayer">
      <select [(ngModel)]="selectedLeaguePlayer">
        <option *ngFor="let leaguePlayer of leaguePlayers" [ngValue]="leaguePlayer">
          {{ leaguePlayer.player.firstName }} {{ leaguePlayer.player.lastName }}
        </option>
      </select>
    </div>
  </div>

  <div *ngIf="weeks.length" class="d-flex flex-fill flex-wrap justify-content-center p-2">
    <button *ngFor="let week of weeks" (click)="selectWeek(week)" class="btn btn-dark btn-lg">
      {{ week.weekNumber }}
    </button>
  </div>

  <div *ngIf="selectedLeaguePlayer && leagueGames && leagueGames.length > 0">
    <app-game
      *ngFor="let leagueGame of leagueGames"
      [leaguePlayerId]="selectedLeaguePlayer.id"
      [leagueGame]="leagueGame"
    ></app-game>
  </div>
</div>
