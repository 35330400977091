import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { GameSummary } from 'src/model/game';

@Component({
  selector: 'app-league-game-selector',
  templateUrl: './league-game-selector.component.html',
  styleUrls: ['./league-game-selector.component.css'],
})
export class LeagueGameSelectorComponent {
  // inputs
  @Input() leagueId: number;
  @Input() game: GameSummary;
  @Input() isSelected: boolean;

  constructor(private api: ApiService) {}

  onClick() {
    // our behavior depends on whether or not the game is already selected
    if (this.isSelected) {
      this.api.deleteLeagueGame$(this.leagueId, this.game.id).subscribe({
        next: () => {
          this.isSelected = false;
        },
        error: () => {
          console.error('Error deleting game from league');
        },
      });
    } else {
      this.api.addLeagueGame$(this.leagueId, this.game.id).subscribe({
        next: () => {
          this.isSelected = true;
        },
        error: () => {
          console.error('Error adding game to league');
        },
      });
    }
  }
}
