<div class="h-100 overflow-auto">
  <div class="d-flex justify-content-center p-2">
    <div *ngIf="weeks.length" class="d-flex flex-fill flex-wrap justify-content-center p-2">
      <button *ngFor="let week of weeks" (click)="selectWeek(week)" class="btn btn-dark btn-lg">
        {{ week.weekNumber }}
      </button>
    </div>
  </div>

  <div *ngIf="games.length">
    <app-league-game-selector
      *ngFor="let game of games"
      [leagueId]="leagueId"
      [game]="game.game"
      [isSelected]="game.isSelected"
    ></app-league-game-selector>
  </div>
</div>
