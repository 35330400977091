import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/store/user/user.reducer';
import { getLeague, getLeaguePlayerId, getUserId } from 'src/app/store/user/user.selector';
import { LeagueGameSummary } from 'src/model/league-game';
import { LeaguePlayerSummary } from 'src/model/league-player';
import { Season } from 'src/model/season';
import { WeekSummary } from 'src/model/week';

@Component({
  selector: 'app-picks',
  templateUrl: './picks.component.html',
  styleUrls: ['./picks.component.css'],
})
export class PicksComponent implements OnInit {
  // loading?
  loading = true;

  // observables
  leaguePlayerId$: Observable<number | undefined>;

  // league id
  leagueId: number | undefined;

  // all seasons
  seasons: Season[] | undefined;

  // selected season
  selectedSeason: Season | undefined;

  // players
  leaguePlayers: LeaguePlayerSummary[] = [];

  // selected player
  selectedLeaguePlayer: LeaguePlayerSummary | undefined;

  // season weeks
  weeks: WeekSummary[] = [];

  // selected week
  selectedWeek: number | undefined;

  // league games
  leagueGames: LeagueGameSummary[];

  constructor(private api: ApiService, private store: Store<State>) {
    combineLatest([this.store.select(getUserId), this.store.select(getLeague)]).subscribe(([playerId, league]) => {
      // set the league id
      this.leagueId = league.id;

      // get all league seasons
      this.api.getSeasonsByLeagueId$(this.leagueId).subscribe({
        next: (res) => {
          this.seasons = res;

          // get the current season
          this.api.findCurrentSeasonByLeagueIdAndPlayerId$(1, playerId).subscribe({
            next: (res) => {
              // select the current season
              this.selectedSeason = this.seasons.find((s) => s.id === res.id);

              // get all weeks
              this.api.findWeeksBySeasonId$(this.selectedSeason.id).subscribe({
                next: (res) => {
                  this.weeks = res;

                  // find the current week
                  this.api.findCurrentWeekBySeasonId$(this.selectedSeason.id).subscribe({
                    next: (res) => {
                      this.selectWeek(res);
                    },
                  });

                  // get all league players participating in the season
                  this.api.findLeaguePlayersByLeagueId$(this.leagueId, this.selectedSeason.id).subscribe({
                    next: (res) => {
                      this.leaguePlayers = res;

                      // set the logged in user as the current player
                      this.selectedLeaguePlayer = this.leaguePlayers.find(
                        (leaguePlayer) => leaguePlayer.player.id === playerId
                      );
                    },
                  });
                },
              });
            },
          });
        },
      });
    });
  }

  onSeasonSelected() {
    this.loading = true;

    // get all weeks
    this.api.findWeeksBySeasonId$(this.selectedSeason.id).subscribe({
      next: (res) => {
        this.weeks = res;

        // find the current week
        this.api.findCurrentWeekBySeasonId$(this.selectedSeason.id).subscribe({
          next: (res) => {
            this.selectWeek(res);
          },
        });
      },
    });
  }

  selectWeek(week: WeekSummary) {
    this.loading = true;
    this.selectedWeek = week.id;

    // get all pickable games for the week
    this.api.findLeagueGames$(this.leagueId, { seasonId: this.selectedSeason.id, weekId: week.id }).subscribe({
      next: (res) => {
        this.leagueGames = res.leagueGames;
        this.loading = false;
      },
    });
  }

  ngOnInit() {
    this.leaguePlayerId$ = this.store.select(getLeaguePlayerId);
  }
}
