import { createReducer, on } from '@ngrx/store';
import { LeagueSummary } from 'src/model/league';
import { Player } from 'src/model/player';
import {
  clearUserId,
  setLeague,
  setLeagueAdministrator,
  setLeaguePlayerId,
  setLeagues,
  setPlayer,
  setUserId,
} from './user.actions';

export interface State {
  userId: number | undefined;
  player: Player | undefined;
  leagues: LeagueSummary[] | undefined;
  league: LeagueSummary | undefined;
  leaguePlayerId: number | undefined;
  isLeagueAdministrator: boolean;
}

export const initialState: State = {
  userId: undefined,
  player: undefined,
  leagues: undefined,
  league: undefined,
  leaguePlayerId: undefined,
  isLeagueAdministrator: false,
};

export const userReducer = createReducer(
  initialState,
  on(setUserId, (state, { userId }) => ({ ...state, userId })),
  on(setPlayer, (state, { player }) => ({ ...state, player })),
  on(setLeagues, (state, { leagues }) => ({ ...state, leagues })),
  on(setLeague, (state, { league }) => ({ ...state, league })),
  on(setLeaguePlayerId, (state, { leaguePlayerId }) => ({ ...state, leaguePlayerId })),
  on(setLeagueAdministrator, (state, { isLeagueAdministrator }) => ({ ...state, isLeagueAdministrator })),
  on(clearUserId, () => initialState)
);
